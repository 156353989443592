// method used to get the data from database
import React, { useState } from "react";
import axios from "axios";

export const getReviews = async ({
  setTotalReviews,
  average,
  setReviews,
  jwt,
  CurrentCourseID,
  
}) => {
  const API_KEY = "90936e6d-4870-409f-9175-b15d7fad693a";

  const Header = {
    Authorization: `Bearer ${API_KEY}`,
  };
  await axios
      //https://impossible-store-production.up.railway.app/login?key=90936e6d-4870-409f-9175-b15d7fad693a

    .get(`https://impossible-store-production.up.railway.app/getReviews/${CurrentCourseID}?key=90936e6d-4870-409f-9175-b15d7fad693a`,{}
    )
    .then((response) => {
      setReviews(response.data);
      setTotalReviews(response.data.length);
    })
    .catch((error) => {
      console.error(error);
      console.log(error)
      alert("cant get reviews");
    });
};
